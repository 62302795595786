import {createReducer} from '@reduxjs/toolkit';

import {DELETE_NOTIFICATIONS, NotificationState, SAVE_NOTIFICATIONS, SaveNotificationsAction} from "./types";

const initialState: NotificationState = [];

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(SAVE_NOTIFICATIONS, (state, action: SaveNotificationsAction) => action.payload)
        .addCase(DELETE_NOTIFICATIONS, () => [])
});