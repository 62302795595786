import React, {ChangeEvent, Component, FormEvent} from "react";
import validate from "validate.js";
import produce from "immer";
import {Form, Input} from "reactstrap";
import {toast} from "react-toastify";

import {handleErrorResponse} from "../../../../helpers/errorHandler";
import * as securityApi from "../../../../client/api/securityApi";

import styles from "./index.module.scss";

const formValidation = {
    email: {
        presence: {
            allowEmpty: false,
            message: "^Please enter email address"
        },
        email: {
            message: "^Please enter a correct email address"
        }
    }
};

interface State {
    isSubmitted: boolean,
    values: {
        email: string
    },
    errors: {
        email?: string[]
    }
}

class SignUpForm extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            isSubmitted: false,
            values: {
                email: ""
            },
            errors: {}
        }
    }

    render() {
        const {email: emailErrors} = this.state.errors;
        const emailError = emailErrors ? emailErrors[0] : "";
        return (
            <div className={styles.container}>
                <span>Create account:</span>
                <Form onSubmit={this.handleSubmit} autoComplete="off" noValidate>
                    <div className={styles.emailInputContainer}>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={this.state.values.email}
                            invalid={Boolean(emailError)}
                            className={styles.emailInput}
                            onChange={this.handleChangeForm}
                        />
                        <button type="submit" className={styles.btnSignUp}>Sign Up</button>
                    </div>
                </Form>
            </div>
        )
    }

    handleChangeForm = (ev: ChangeEvent<HTMLInputElement>) => {

        const name = ev.target.name as "email";
        const value = ev.target.value;

        this.setState(produce(this.state, draft => {

            draft.values[name] = value;

            if (this.state.isSubmitted) {
                draft.errors[name] = validate.single(value, formValidation[name]);
            }
        }));
    };

    handleSubmit = (ev: FormEvent<HTMLFormElement>) => {

        ev.preventDefault();

        const errors = validate(this.state.values, formValidation);

        if (!errors) {
            securityApi.signUp(this.state.values.email)
                .then(() => toast.success("User account created"))
                .then(() => this.setState({isSubmitted: false, values: {email: ""}}))
                .catch(handleErrorResponse);
        } else {
            this.setState({errors});
        }

        this.setState({isSubmitted: true});
    }
}

export default SignUpForm;