import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {FiBox} from "react-icons/fi";

import styles from "./index.module.scss";

interface Props {
    explorers?: string[]
}

const Explorers = ({explorers}: Props) => {

    const items: any[] = [];

    if (explorers) {
        explorers.forEach(explorer => {
            try {

                const hostname = new URL(explorer).hostname.replace("www.", "");

                let item = (
                    <DropdownItem
                        key={explorer}
                        tag={"a"}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={explorer}
                        onMouseDown={e => e.stopPropagation()}
                    >
                        {hostname}
                    </DropdownItem>
                );

                items.push(item);
            } catch (error) {
                console.error(error);
            }
        });
    }

    return (
        <UncontrolledDropdown className={styles.explorers}>
            <DropdownToggle disabled={items.length === 0} className={styles.btnDropdown}>
                <FiBox/>
            </DropdownToggle>
            {items.length > 0 &&
            <DropdownMenu>
                <DropdownItem header>Explorers</DropdownItem>
                {items}
            </DropdownMenu>}
        </UncontrolledDropdown>
    )
}

export default Explorers;