import React from "react";

import {CustomScrollbars} from "../../components";
import {PasswordChangeForm} from "./components";

import styles from "./index.module.scss";

const PasswordChangeView = () => (
    <CustomScrollbars>
        <section className={styles.wrapper}>
            <div className={styles.content}>
                <PasswordChangeForm/>
            </div>
        </section>
    </CustomScrollbars>
);

export default PasswordChangeView;