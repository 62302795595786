import React, {Component} from "react";
import {useParams} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";
import {bindActionCreators} from "redux";
import {push} from "@lagunovsky/redux-react-router";
import {AxiosResponse} from "axios";
import {Col, Container, Row} from "reactstrap";

import {BITCOIN} from "../../../../helpers/constants";
import {AppDispatch} from "../../../../store/store";
import {Asset, ErrorResponse} from "../../../../client/types";
import * as arbitrationApi from "../../../../client/api/spotArbitrageApi";
import {ArbitrageItem, ArbitrageProfit, Token} from "./helpers/types";
import * as arbitrageItemMapper from "./helpers/arbitrageItemMapper";
import * as arbitrageProfitMapper from "./helpers/arbitrageProfitMapper";
import * as tokenMapper from "./helpers/tokenMapper";
import {
    filterDestItemsByProfit,
    filterProfitsBySrcItem,
    findItem,
    sortChainsByStatus,
    sortChainsByTopRoute,
    sortItemsByProfit
} from "./helpers/arbitrageUtils";
import {ArbitrageItems, Explorers, Favorite, Mute, Ticker, Tokens} from "./components";

import styles from "./index.module.scss"

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({push}, dispatch);

const connector = connect(null, mapDispatchToProps);

interface Props extends ConnectedProps<typeof connector> {
    assetId: number
}

interface State {
    asset?: Asset
    tokens?: Token[]
    items?: ArbitrageItem[]
    profits?: ArbitrageProfit[]
    srcItems?: ArbitrageItem[]
    destItems?: ArbitrageItem[]
    selectedSrcItemKey?: string
    selectedDestItemKey?: string
    selectedProfit?: ArbitrageProfit
    explorers?: string[]
    favorite?: boolean
    mutedUntilDate?: string
}

class AssetDetails extends Component<Props, State> {

    private updateTask?: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.fetchArbitrageAsset();
        this.updateTask = setInterval(this.fetchArbitrageAsset, 1000);
    }

    render = () => (
        <section className={styles.assetDetails}>
            <div className={styles.topbar}>
                <Ticker asset={this.state.asset} profit={this.state.selectedProfit}/>
                <div className={styles.right}>
                    <Mute assetId={this.props.assetId} mutedUntilDate={this.state.mutedUntilDate}/>
                    <Tokens tokens={this.state.tokens}/>
                    <Explorers explorers={this.state.explorers}/>
                    <Favorite assetId={this.props.assetId} isFavorite={this.state.favorite}/>
                </div>
            </div>
            <div className={styles.main}>
                <Container fluid className={styles.header}>
                    <Row>
                        <Col>Exchange</Col>
                        <Col>Market</Col>
                        <Col xs={2}>Chain</Col>
                        <Col xs={2}>Confirm</Col>
                        <Col xs={2}>Fee</Col>
                        <Col xs={2}>Status</Col>
                    </Row>
                </Container>
                <ArbitrageItems items={this.state.srcItems}
                                selectedItemKey={this.state.selectedSrcItemKey}
                                usedForBuying={true}
                                selectItem={this.handleSelectSrcItem}
                />
                <div className={styles.divider}/>
                <ArbitrageItems items={this.state.destItems}
                                selectedItemKey={this.state.selectedDestItemKey}
                                selectItem={this.handleSelectDestItem}
                />
            </div>
        </section>
    )

    componentWillUnmount() {
        clearInterval(this.updateTask as NodeJS.Timeout);
    }

    handleSelectSrcItem = (itemKey: string) => {
        this.updateState({...this.state, selectedSrcItemKey: itemKey})
    }

    handleSelectDestItem = (itemKey: string) => {
        this.updateState({...this.state, selectedDestItemKey: itemKey})
    }

    updateState = (state: State) => {

        const srcItems: ArbitrageItem[] = JSON.parse(JSON.stringify(state.items));
        sortItemsByProfit(srcItems, state.profits!, true);

        let selectedSrcItem = findItem(srcItems, state.selectedSrcItemKey!);

        if (!selectedSrcItem) {
            selectedSrcItem = srcItems[0];
        }

        let destItems: ArbitrageItem[] = [];

        if (selectedSrcItem) {
            const selectedSrcProfits = filterProfitsBySrcItem(selectedSrcItem, state.profits!);
            destItems = JSON.parse(JSON.stringify(state.items));
            destItems = filterDestItemsByProfit(destItems, selectedSrcProfits);
            sortItemsByProfit(destItems, selectedSrcProfits)
        }

        let selectedDestItem = findItem(destItems, state.selectedDestItemKey!);

        if (!selectedDestItem) {
            selectedDestItem = destItems[0];
        }

        if (selectedSrcItem && selectedDestItem) {
            sortChainsByTopRoute(selectedSrcItem.chains, selectedDestItem.chains);
            srcItems.forEach(item => sortChainsByTopRoute(item.chains, selectedDestItem!.chains.slice(0, 1)))
            destItems.forEach(item => sortChainsByTopRoute(item.chains, selectedSrcItem!.chains.slice(0, 1)))
            destItems.forEach(item => sortChainsByStatus(item.chains))
        }

        const srcItemKey = selectedSrcItem ? selectedSrcItem.markets[0].key : undefined;
        const destItemKey = selectedDestItem ? selectedDestItem.markets[0].key : undefined;
        const profit = state.profits!.find(p => p.srcMarketKey === srcItemKey && p.destMarketKey === destItemKey);

        this.setState({
            ...state,
            srcItems: srcItems,
            destItems: destItems,
            selectedSrcItemKey: srcItemKey,
            selectedDestItemKey: destItemKey,
            selectedProfit: profit
        });
    }

    fetchArbitrageAsset = () => {
        arbitrationApi.fetchArbitrageAsset(this.props.assetId)
            .then(({data}) => {

                const tokenContractWithFee = data.tokenContracts.filter(token => token.buyFee || token.sellFee);
                const tokens = tokenMapper.convertTokenContracts(tokenContractWithFee);

                const items: ArbitrageItem[] = [];
                items.push(...arbitrageItemMapper.convertExchangeAssets(data.exchangeAssets));
                items.push(...arbitrageItemMapper.convertTokenContracts(data.tokenContracts));

                const profits: ArbitrageProfit[] = [];
                profits.push(...arbitrageProfitMapper.convertSpotVsSpot(data.spotVsSpotProfits));
                profits.push(...arbitrageProfitMapper.convertSpotVsDex(data.spotVsDexProfits, false));
                profits.push(...arbitrageProfitMapper.convertSpotVsDex(data.dexVsSpotProfits, true));

                this.updateState({
                    asset: data.asset,
                    tokens,
                    items,
                    profits,
                    selectedSrcItemKey: this.state.selectedSrcItemKey,
                    selectedDestItemKey: this.state.selectedDestItemKey,
                    explorers: data.explorers,
                    favorite: data.favorite,
                    mutedUntilDate: data.mutedUntilDate
                });
            })
            .catch((resp: AxiosResponse<ErrorResponse>) => {
                if (resp && resp.status === 404) {
                    this.props.push(`${BITCOIN}`);
                }
            });
    }
}

const AssetDetailsWithRouter = (props: any) => {
    const {assetId} = useParams();
    const Component = connector(AssetDetails);
    return <Component {...props} key={assetId} assetId={assetId}/>
}

export default AssetDetailsWithRouter