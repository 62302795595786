import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {ACCOUNTS_PATH, SPOT_ARBITRAGE_PATH, PASSWORD_CHANGE_PATH} from "../../helpers/constants";
import {AdminRoute} from "../../components";
import {AccountsView, SpotArbitrageView, PasswordChangeView} from "../../views";
import {Topbar} from "./components";

import styles from "./index.module.scss";

const MainLayout = () => (
    <section className={styles.container}>
        <Topbar/>
        <div className={styles.content}>
            <Routes>
                <Route path={`${SPOT_ARBITRAGE_PATH}/*`} element={<SpotArbitrageView/>}/>
                <Route path={ACCOUNTS_PATH} element={<AdminRoute/>}>
                    <Route index element={<AccountsView/>}/>
                </Route>
                <Route path={PASSWORD_CHANGE_PATH} element={<PasswordChangeView/>}/>
                <Route path="*" element={<Navigate to={SPOT_ARBITRAGE_PATH}/>}/>
            </Routes>
        </div>
    </section>
);

export default MainLayout;