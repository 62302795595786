import React, {ChangeEvent, Component, FormEvent} from "react";
import {Link} from "react-router-dom";
import {AxiosResponse} from "axios";
import validate from "validate.js";
import produce from "immer";
import {Button, Form, FormGroup} from "reactstrap";

import {PASSWORD_RECOVERY_PATH} from "../../../../helpers/constants"
import {handleErrorResponse} from "../../../../helpers/errorHandler";
import {User} from "../../../../client/types";
import * as securityApi from "../../../../client/api/securityApi";
import {FloatingLabelInput} from "../../../../components";

import styles from "./index.module.scss";

const formValidation = {
    email: {
        presence: {
            allowEmpty: false,
            message: "^Please enter email address"
        },
        email: {
            message: "^Please enter a correct email address"
        }
    },
    password: {
        presence: {
            allowEmpty: false,
            message: "^Please enter password"
        }
    }
};

interface Props {
    saveUser: Function
}

interface State {
    isSubmitted: boolean,
    values: {
        email: string,
        password: string
    },
    errors: {
        email?: string[],
        password?: string[]
    }
}

class SignInForm extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isSubmitted: false,
            values: {
                email: "",
                password: ""
            },
            errors: {}
        }
    }

    render() {
        const {email: emailErrors, password: passwordErrors} = this.state.errors;
        const emailError = emailErrors ? emailErrors[0] : "";
        const passwordError = passwordErrors ? passwordErrors[0] : "";
        return (
            <section className={styles.content}>
                <h3 className={styles.title}>Sign In</h3>
                <h6 className={styles.subTitle}>Sign in on the internal platform</h6>
                <Form onSubmit={this.handleSubmit} autoComplete="off" noValidate>
                    <FormGroup className="mb-4">
                        <FloatingLabelInput
                            id="email"
                            name="email"
                            type="email"
                            label="Email"
                            value={this.state.values.email}
                            invalid={Boolean(emailError)}
                            helperText={emailError}
                            onChange={this.handleChangeForm}
                            autoFocus
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <FloatingLabelInput
                            id="password"
                            name="password"
                            type="password"
                            label="Password"
                            value={this.state.values.password}
                            invalid={Boolean(passwordError)}
                            helperText={passwordError}
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>
                    <Button type="submit" color="success" block>Sign In</Button>
                </Form>
                <Link to={`/${PASSWORD_RECOVERY_PATH}`} className={styles.link}>Forgot password?</Link>
            </section>
        )
    }

    handleChangeForm = (ev: ChangeEvent<HTMLInputElement>) => {

        const name = ev.target.name as "email" | "password";
        const value = ev.target.value;

        this.setState(produce(this.state, draft => {

            draft.values[name] = value;

            if (this.state.isSubmitted) {
                draft.errors[name] = validate.single(value, formValidation[name]);
            }
        }));
    };

    handleSubmit = (ev: FormEvent<HTMLFormElement>) => {

        ev.preventDefault();

        const errors = validate(this.state.values, formValidation);

        if (!errors) {
            securityApi.signIn(this.state.values.email, this.state.values.password)
                .then((response: AxiosResponse<User>) => this.props.saveUser(response.data))
                .catch(handleErrorResponse);
        } else {
            this.setState({errors});
        }

        this.setState({isSubmitted: true});
    };
}

export default SignInForm;