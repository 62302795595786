import React from "react";
import {ScrollbarProps, Scrollbars} from "react-custom-scrollbars";

import styles from "./index.module.scss";

interface Props extends ScrollbarProps {
    children: React.ReactNode
}


const CustomScrollbars = (props: Props) => (
    <Scrollbars
        renderThumbVertical={props => <div {...props} className={styles.scrollbarThumb}/>}
        renderThumbHorizontal={props => <div {...props} className={styles.scrollbarThumb}/>}
        {...props}
    />
)

export default CustomScrollbars;
