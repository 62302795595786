import React, {useCallback, useMemo, useState} from "react";

import {USER} from "../../helpers/constants";
import AuthContext, {User} from "../AuthContext";

type UserJson = string | null;

function AuthProvider({children}: any) {

    const [userJson, setUserJson] = useState<UserJson>(localStorage.getItem(USER));

    const getUser = useCallback(() => {
        return userJson ? JSON.parse(userJson) : null;
    }, [userJson]);

    const saveUser = useCallback((user: User) => {
        const userJson = JSON.stringify(user);
        setUserJson(userJson);
        localStorage.setItem(USER, userJson);
    }, []);

    const removeUser = useCallback(() => {
        setUserJson(null);
        localStorage.removeItem(USER);
    }, []);

    const user = useMemo(() => getUser(), [getUser]);

    return (
        <AuthContext.Provider value={{user, saveUser, removeUser}}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;