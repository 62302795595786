import React, {Component} from "react";
import classNames from "classnames";

import {TopArbitrages} from "./components";

import styles from "./index.module.scss";

enum Tab {
    New = "new",
    Fav = "fav",
    Muted = "muted"
}

interface State {
    tab: Tab
}

class Sidebar extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {tab: Tab.New}
    }

    render = () => (
        <section className={styles.sidebar}>
            <div className={styles.tabs}>
                <button className={classNames([styles.tabItem], {[styles.active]: this.state.tab === Tab.New})}
                        onMouseUp={() => this.handleSelectTab(Tab.New)}
                >
                    NEW
                </button>
                <button className={classNames([styles.tabItem], {[styles.active]: this.state.tab === Tab.Fav})}
                        onMouseUp={() => this.handleSelectTab(Tab.Fav)}
                >
                    FAV
                </button>
                <button className={classNames([styles.tabItem], {[styles.active]: this.state.tab === Tab.Muted})}
                        onMouseUp={() => this.handleSelectTab(Tab.Muted)}
                >
                    MUTED
                </button>
            </div>
            <TopArbitrages key={this.state.tab} type={this.state.tab}/>
        </section>
    )

    handleSelectTab = (tab: Tab) => {
        this.setState({tab});
    }
}

export default Sidebar;