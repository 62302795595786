import React from "react";
import {Spinner} from "reactstrap";

import styles from "./index.module.scss";

const LoadingPane = () => (
    <div className={styles.container}>
        <Spinner className={styles.spinner}/>
    </div>
)

export default LoadingPane;
