import React, {Component} from "react";
import classNames from "classnames";

import styles from "./index.module.scss";

interface Props extends React.HTMLProps<HTMLInputElement> {
    helperText?: string,
    invalid?: boolean
}

interface State {
    hasFocus?: boolean,
    hasValue?: boolean
}

class FloatingLabelInput extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    static getDerivedStateFromProps(props: Props) {
        return {hasValue: Boolean(props.value)};
    }

    render() {

        const {label, helperText, invalid, ...rest} = this.props;

        return (
            <div className={styles.rootContainer}>
                <label className={classNames({
                    [styles.focus]: Boolean(this.state.hasFocus && !this.props.invalid),
                    [styles.invalid]: this.props.invalid,
                    [styles.shrink]: Boolean(this.state.hasFocus || this.state.hasValue)
                })}>
                    {label}
                </label>
                <div className={classNames([styles.inputContainer], {
                    [styles.focus]: Boolean(this.state.hasFocus && !this.props.invalid),
                    [styles.invalid]: this.props.invalid
                })}>
                    <input
                        {...rest}
                        className={classNames({[styles.invalid]: this.props.invalid})}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                    />
                    <fieldset className={classNames({[styles.focus]: this.state.hasFocus})}>
                        <legend className={classNames({
                            [styles.shrink]: Boolean(this.state.hasFocus || this.state.hasValue)
                        })}>
                            <span>{label}</span>
                        </legend>
                    </fieldset>
                </div>
                <small className={classNames({[styles.invalid]: invalid})}>
                    {helperText}
                </small>
            </div>
        );
    }

    onFocus = (ev: React.FocusEvent<HTMLInputElement>) => {

        this.setState({hasFocus: true});

        if (this.props.onFocus) {
            this.props.onFocus(ev);
        }
    };

    onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {

        this.setState({hasFocus: false});

        if (this.props.onBlur) {
            this.props.onBlur(ev);
        }
    };

    onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({hasValue: Boolean(ev.target.value)});

        if (this.props.onChange) {
            this.props.onChange(ev);
        }
    }
}

export default FloatingLabelInput;