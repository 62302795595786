import axios, {AxiosError, AxiosResponse} from "axios";

import {USER} from "./constants";
import {ErrorResponse} from "../client/types";

export const customAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    withCredentials: true
});

customAxios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError<ErrorResponse>) => {
        if (!error.response) {
            console.log("Network error");
        } else if (error.response.status === 401) {
            localStorage.removeItem(USER);
            window.location.reload();
        }
        return Promise.reject(error.response);
    }
);