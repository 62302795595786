import {ArbitrageItem, ArbitrageProfit, Chain, TransferRoute} from "./types";

export const findItem = (items: ArbitrageItem[], marketKey: string): ArbitrageItem | undefined => {
    for (const item of items) {
        if (item.markets.find(market => market.key === marketKey)) return item;
    }
    return undefined;
}

export const filterProfitsBySrcItem = (item: ArbitrageItem, profits: ArbitrageProfit[]): ArbitrageProfit[] => {
    const srcMarketKeys = new Set();
    item.markets.forEach(market => srcMarketKeys.add(market.key))
    return profits.filter(profit => srcMarketKeys.has(profit.srcMarketKey));
}

export const filterDestItemsByProfit = (items: ArbitrageItem[], profits: ArbitrageProfit[]): ArbitrageItem[] => {
    const destMarketKeys = new Set();
    profits.forEach(profit => destMarketKeys.add(profit.destMarketKey))
    return items.filter(item => {
        for (const market of item.markets) {
            if (destMarketKeys.has(market.key)) {
                return true;
            }
        }
        return false;
    });
}

export const sortItemsByProfit = (items: ArbitrageItem[], profits: ArbitrageProfit[], bySrc?: boolean) => {

    const maxProfits: Map<string, number> = new Map();

    for (const profit of profits) {
        const marketKey = bySrc ? profit.srcMarketKey : profit.destMarketKey;
        const maxProfit = maxProfits.get(marketKey);
        if (!maxProfit || profit.profit > maxProfit) {
            maxProfits.set(marketKey, profit.profit);
        }
    }

    for (const item of items) {
        for (let i = 0; i < item.markets.length; i++) {
            item.markets[i].hasProfit = Boolean(maxProfits.get(item.markets[i].key));
        }
    }

    for (const item of items) {
        item.markets.sort((m1, m2) => comparingNumber(maxProfits.get(m1.key), maxProfits.get(m2.key)));
    }

    items.sort((i1, i2) => comparingNumber(maxProfits.get(i1.markets[0].key), maxProfits.get(i2.markets[0].key)));
}

export const sortChainsByTopRoute = (srcChains: Chain[], destChains: Chain[]) => {

    const srcChainsCopy = [...srcChains];
    const destChainsCopy = [...destChains];

    srcChainsCopy.sort((n1, n2) => comparingNullsLast(n1.id, n2.id));
    destChainsCopy.sort((n1, n2) => comparingNullsLast(n1.id, n2.id));

    const matchRoutes: TransferRoute[] = [];
    const restRoutes: TransferRoute[] = [];

    for (const srcChain of srcChains) {
        for (let i = 0; i < destChains.length; i++) {

            const destChain = destChains[i];
            const isValidRoute = srcChain.withdrawEnabled && destChain.depositEnabled;

            if (!srcChain.id || !destChain.id) {
                if (isValidRoute) restRoutes.push({srcChain, destChain})
            } else if (srcChain.id === destChain.id) {
                if (isValidRoute) matchRoutes.push({srcChain, destChain})
                destChainsCopy.splice(i, 1);
                break;
            }
        }
    }

    const topRoute = matchRoutes.length ? findMinFeeRoute(matchRoutes) : findMinFeeRoute(restRoutes);

    if (topRoute) {
        srcChains.splice(srcChains.indexOf(topRoute.srcChain), 1);
        destChains.splice(destChains.indexOf(topRoute.destChain), 1);
        srcChains.unshift(topRoute.srcChain);
        destChains.unshift(topRoute.destChain);
    }
}

function findMinFeeRoute(routes: TransferRoute[]): TransferRoute | undefined {

    let minFeeRoute;

    for (const route of routes) {
        const fee = route.srcChain.fixedWithdrawFee;
        const minFee = minFeeRoute ? minFeeRoute.srcChain.fixedWithdrawFee : undefined;
        if (!minFee || !fee || fee < minFee) {
            minFeeRoute = route;
        }
    }

    return minFeeRoute;
}

export const sortChainsByStatus = (chains: Chain[]) => {
    chains.sort((c1, c2) => comparingNumber(calcChainPriorityByStatus(c1), calcChainPriorityByStatus(c2)))
}

function calcChainPriorityByStatus(chain: Chain) {
    if (chain.depositEnabled && chain.withdrawEnabled) {
        return 4;
    } else if (!chain.withdrawEnabled && chain.depositEnabled) {
        return 3;
    } else if (chain.withdrawEnabled) {
        return 2;
    } else {
        return 1;
    }
}

function comparingNullsLast(o1?: any, o2?: any) {

    if (o1 && !o2) {
        return -1;
    } else if (!o1 && o2) {
        return 1;
    }

    return 0;
}

// Comparing numbers desc null last
function comparingNumber(n1?: number, n2?: number) {

    if (n1 && n2) {
        return n2 - n1;
    } else if (n1 && !n2) {
        return -1;
    } else if (!n1 && n2) {
        return 1;
    }

    return 0;
}