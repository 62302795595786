import {AxiosResponse} from "axios";

import {customAxios} from "../../helpers/customAxios";
import {Page, User} from "../types";

const USERS_PATH = "/users";
const USER_STATUS_PATH = "/users/status";

export const fetchUsers = (page?: number, size?: number, sort?: string): Promise<AxiosResponse<Page<User[]>>> => (
    customAxios.get(USERS_PATH, {params: {page, size, sort}})
);

export const updateUserStatus = (id: number, enabled: boolean): Promise<AxiosResponse> => (
    customAxios.patch(USER_STATUS_PATH, {id, enabled})
);