import React, {useRef} from "react";
import {UncontrolledTooltip} from "reactstrap";
import {FaExclamation} from "react-icons/fa";

import styles from "./index.module.scss";

interface Props {
    warning?: string,
    hasProfit?: boolean
}

const Mark = ({warning, hasProfit}: Props) => {
    const ref = useRef(null);
    return (<>
        {warning && <>
            <span ref={ref} className={styles.warningSign}><FaExclamation/></span>
            <UncontrolledTooltip placement="left" target={ref}>
                <div className={styles.warningMsg}>{warning}</div>
            </UncontrolledTooltip>
        </>}
        {hasProfit && <span className={styles.profitSign}>$</span>}
    </>)
}

export default Mark;