import {createContext} from "react";

export type User = {
    email: string;
    role: string;
};

export type Values = {
    user: User | null;
    saveUser: Function;
    removeUser: Function;
};

const AuthContext = createContext({} as Values);

export default AuthContext;