import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {ReduxRouter} from "@lagunovsky/redux-react-router";

import {browserHistory} from "../../helpers/browserHistory";
import {APP_PATH, PASSWORD_RECOVERY_PATH, SIGH_IN_PATH} from "../../helpers/constants";
import {MainLayout} from "../../layouts";
import {PasswordRecoveryView, SignInView} from "../../views";
import {NotificationProvider, PrivateRoute, PublicRoute} from "..";

const AppRoutes = () => (
    <ReduxRouter history={browserHistory}>
        <Routes>
            <Route path={SIGH_IN_PATH} element={<PublicRoute/>}>
                <Route index element={<SignInView/>}/>
            </Route>
            <Route path={PASSWORD_RECOVERY_PATH} element={<PublicRoute/>}>
                <Route index element={<PasswordRecoveryView/>}/>
            </Route>
            <Route path={APP_PATH} element={<PrivateRoute/>}>
                <Route index path="*" element={<NotificationProvider><MainLayout/></NotificationProvider>}/>
            </Route>
            <Route path="*" element={<Navigate to={SIGH_IN_PATH}/>}/>
        </Routes>
    </ReduxRouter>
)

export default AppRoutes;