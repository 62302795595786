import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import classNames from "classnames";

import {SpotArbitrage} from "../../../../../../client/types";
import * as arbitrageApi from "../../../../../../client/api/spotArbitrageApi";
import {CustomScrollbars, EmptyPane, LoadingPane} from "../../../../../../components";

import styles from "./index.module.scss";

interface Props {
    type: "new" | "fav" | "muted"
}

interface State {
    topArbitrages?: SpotArbitrage[]
}

class TopArbitrages extends Component<Props, State> {

    private updateTask?: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.fetchTopArbitrages(this.props.type);
        this.updateTask = setInterval(() => this.fetchTopArbitrages(this.props.type), 1000);
    }

    render = () => (
        <div className={styles.container}>
            <div className={styles.navHeader}>
                <span className={styles.asset}>Asset</span>
                <span className={styles.investment}>Investment</span>
                <span className={styles.profit}>Profit</span>
            </div>
            {!this.state.topArbitrages && <LoadingPane/>}
            {this.state.topArbitrages && (this.state.topArbitrages.length === 0) && <EmptyPane/>}
            {this.state.topArbitrages && (this.state.topArbitrages.length > 0) &&
                <CustomScrollbars>
                    {this.state.topArbitrages.map(item => {
                        const {investment, profit} = item;
                        const i = investment >= 1 ? `${Math.round(investment)} $` : investment !== 0 ? "< 1 $" : "\u2013";
                        const p = profit >= 1 ? `${Math.round(profit)} $` : profit !== 0 ? "< 1 $" : "\u2013";
                        return (
                            <NavLink
                                key={item.asset.id} to={item.asset.id.toString()}
                                className={({isActive}) => classNames([styles.navItem], {[styles.active]: isActive})}
                            >
                                <span className={styles.asset}>{item.asset.symbol.toUpperCase()}</span>
                                <span className={styles.investment}>{i}</span>
                                <span className={styles.profit}>{p}</span>
                            </NavLink>
                        )
                    })}
                </CustomScrollbars>
            }
        </div>
    )

    componentWillUnmount() {
        clearInterval(this.updateTask as NodeJS.Timeout);
    }

    fetchTopArbitrages = (type: "new" | "fav" | "muted") => {
        arbitrageApi.fetchTopArbitrage(type).then(({data}) => this.setState({topArbitrages: data}));
    }
}

export default TopArbitrages;