import React from "react";
import {SiDropbox} from "react-icons/si";

import styles from "./index.module.scss";

const EmptyPane = () => (
    <div className={styles.container}>
        <SiDropbox className={styles.icon}/>
        <span className={styles.message}>No Records</span>
    </div>
)

export default EmptyPane;
