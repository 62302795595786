import React, {useRef} from "react";
import {Table, UncontrolledTooltip} from "reactstrap";
import {IoIosArrowDown} from "react-icons/io";

import {Chain} from "../../../../helpers/types";

import styles from "./index.module.scss";

interface Props {
    chains: Chain[]
}

const Chains = ({chains}: Props) => {
    const ref = useRef(null);
    return (<>
        {chains.length < 2 && (chains.length !== 0 ? chains[0].symbol : "\u2013")}
        {chains.length > 1 && <>
            <div ref={ref} className={styles.btnChain} onMouseUp={e => e.stopPropagation()}>
                {chains[0].symbol}
                <span className={styles.arrow}><IoIosArrowDown/></span>
            </div>
            <UncontrolledTooltip target={ref} placement="bottom" className={styles.networks}>
                <Table borderless>
                    <thead>
                    <tr>
                        <th className={styles.colChain}>Chain</th>
                        <th className={styles.colConfirm}>Confirm</th>
                        <th className={styles.colFee}>Fee</th>
                        <th className={styles.colStatus}>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {chains.map(chain => (
                        <tr>
                            <td className={styles.colChain}>{chain.symbol}</td>
                            <td className={styles.colConfirm}>{chain.confirm}</td>
                            <td className={styles.colFee}>{chain.withdrawFee}</td>
                            <td className={styles.colStatus}>{chain.status}</td>
                        </tr>)
                    )}
                    </tbody>
                </Table>
            </UncontrolledTooltip>
        </>}
    </>)
}

export default Chains;