import {AxiosResponse} from "axios";

import {customAxios} from "../../helpers/customAxios";
import {SpotArbitrage, SpotArbitrageAsset} from "../types";

const SPOT_ARBITRAGE_PATH = "/arbitrage/spot";
const SPOT_ARBITRAGE_ASSETS_PATH = "/arbitrage/spot/assets";

export const fetchTopArbitrage = (type: "new" | "fav" | "muted"): Promise<AxiosResponse<SpotArbitrage[]>> => (
    customAxios.get(`${SPOT_ARBITRAGE_PATH}/${type}`)
);

export const fetchArbitrageAsset = (assetId: number): Promise<AxiosResponse<SpotArbitrageAsset>> => (
    customAxios.get(`${SPOT_ARBITRAGE_ASSETS_PATH}/${assetId}`)
);

export const favoriteArbitrageAsset = (assetId: number): Promise<AxiosResponse> => (
    customAxios.post(`${SPOT_ARBITRAGE_ASSETS_PATH}/${assetId}/favorite`)
);

export const muteArbitrageAsset = (assetId: number, numberOfMinutes: number): Promise<AxiosResponse> => (
    customAxios.post(`${SPOT_ARBITRAGE_ASSETS_PATH}/${assetId}/mute`, null, {params: {numberOfMinutes}})
);