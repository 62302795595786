import React, {ChangeEvent, Component, FormEvent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect, ConnectedProps} from "react-redux";
import {Link} from "react-router-dom";
import {push} from "@lagunovsky/redux-react-router";
import validate from "validate.js";
import produce from "immer";
import {Button, Form, FormGroup} from "reactstrap";
import Countdown from "react-countdown";
import {CountdownRenderProps} from "react-countdown/dist/Countdown";
import classNames from "classnames";
import {toast} from "react-toastify";

import {SIGH_IN_PATH} from "../../../../helpers/constants";
import {AppDispatch} from "../../../../store/store";
import {FloatingLabelInput} from "../../../../components";
import * as securityApi from "../../../../client/api/securityApi";
import {handleErrorResponse} from "../../../../helpers/errorHandler";

import styles from "./index.module.scss";

export const formValidation = {
    email: {
        presence: {
            allowEmpty: false,
            message: "^Please enter email address"
        },
        email: {
            message: "^Please enter a correct email address"
        }
    },
    verificationCode: {
        presence: {
            allowEmpty: false,
            message: "^Please enter verification code"
        },
        numericality: {
            message: "^Please enter 6-digit code"
        }
    },
    password: {
        presence: {
            allowEmpty: false,
            message: "^Please enter password"
        },
        format: {
            pattern: "^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*\\d+)(?=.*[~`!@#$%^&*()+=_\\-{}|:;”’?/<>,.\\]\\[]+).{8,}$",
            message: "^Passwords must be at least 8 characters with one uppercase letter, one special character and one number"
        }
    },
    passwordConfirmation: {
        presence: {
            allowEmpty: false,
            message: "^Please enter the password again"
        },
        equality: {
            attribute: "password",
            message: "^The two passwords are different"
        }
    }
};

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators({push}, dispatch);

const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

interface State {
    values: {
        email: string,
        verificationCode: string,
        password: string,
        passwordConfirmation: string
    },
    errors: {
        email?: string[],
        verificationCode?: string[],
        password?: string[],
        passwordConfirmation?: string[]
    }
    blockSendingCodeUntilTimestamp?: number
}

class PasswordRecoveryForm extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            values: {
                email: "",
                verificationCode: "",
                password: "",
                passwordConfirmation: ""
            },
            errors: {}
        }
    }

    render() {

        const {
            email: emailErrors,
            verificationCode: verificationCodeErrors,
            password: passwordErrors,
            passwordConfirmation: passwordConfirmationErrors
        } = this.state.errors;

        const emailError = emailErrors ? emailErrors[0] : "";
        const verificationCodeError = verificationCodeErrors ? verificationCodeErrors[0] : "";
        const passwordError = passwordErrors ? passwordErrors[0] : "";
        const passwordConfirmationError = passwordConfirmationErrors ? passwordConfirmationErrors[0] : "";

        return (
            <section className={styles.content}>
                <h3 className={styles.title}>Restore Password</h3>
                <h6 className={styles.subTitle}>Change password for the account</h6>
                <Form onSubmit={this.handleSubmit} autoComplete="off" noValidate>
                    <FormGroup className="mb-4">
                        <FloatingLabelInput
                            id="email"
                            name="email"
                            type="email"
                            label="Email"
                            value={this.state.values.email}
                            invalid={Boolean(emailError)}
                            helperText={emailError}
                            onChange={this.handleChangeForm}
                            autoFocus
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <div className={styles.verificationCode}>
                            <FloatingLabelInput
                                id="verificationCode"
                                name="verificationCode"
                                type="text"
                                label="Verification code"
                                value={this.state.values.verificationCode}
                                invalid={Boolean(verificationCodeError)}
                                helperText={verificationCodeError}
                                maxLength={6}
                                onChange={this.handleChangeForm}
                            />
                            <button
                                className={classNames(
                                    [styles.btnSend],
                                    {[styles.disable]: Boolean(this.state.blockSendingCodeUntilTimestamp)}
                                )}
                                onClick={this.handleSendCode}
                            >
                                <Countdown
                                    key={this.state.blockSendingCodeUntilTimestamp}
                                    date={this.state.blockSendingCodeUntilTimestamp}
                                    renderer={this.countdownRenderer}
                                    onComplete={this.countdownComplete}
                                >
                                    <Fragment>Send</Fragment>
                                </Countdown>
                            </button>
                        </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <FloatingLabelInput
                            id="password"
                            name="password"
                            type="password"
                            label="New password"
                            value={this.state.values.password}
                            invalid={Boolean(passwordError)}
                            helperText={passwordError}
                            maxLength={20}
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <FloatingLabelInput
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            type="password"
                            label="Confirm password"
                            value={this.state.values.passwordConfirmation}
                            invalid={Boolean(passwordConfirmationError)}
                            helperText={passwordConfirmationError}
                            maxLength={20}
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>
                    <Button type="submit" color="success" block>Change Password</Button>
                </Form>
                <Link to={`/${SIGH_IN_PATH}`} className={styles.link}>Remembered password?</Link>
            </section>
        )
    }

    countdownRenderer = ({total, completed}: CountdownRenderProps) => {
        if (completed) {
            return <>Send</>
        } else {
            return <>{total / 1000}s</>
        }
    };

    countdownComplete = () => {
        this.setState(produce(this.state, draft => {
            draft.blockSendingCodeUntilTimestamp = 0;
        }));
    };

    handleSendCode = (ev: React.MouseEvent<HTMLElement>) => {

        ev.preventDefault();

        const errors = validate.single(this.state.values.email, formValidation.email);

        if (!errors) {
            securityApi.restorePassword(this.state.values.email)
                .then(() => toast.success("Verification code sent"))
                .then(() => this.setState({blockSendingCodeUntilTimestamp: Date.now() + 60000}))
                .catch(handleErrorResponse);
        } else {
            this.setState(produce(this.state, draft => {
                draft.errors.email = errors;
            }));
        }
    }

    handleChangeForm = (ev: ChangeEvent<HTMLInputElement>) => {

        const name = ev.target.name as "email" | "verificationCode" | "password" | "passwordConfirmation";
        const value = ev.target.value;

        this.setState(produce(this.state, draft => {

            draft.values[name] = value;

            const errors = validate(draft.values, formValidation);

            if (errors) {
                draft.errors[name] = errors[name];
            } else {
                draft.errors[name] = undefined;
            }
        }));
    };

    handleSubmit = (ev: FormEvent<HTMLFormElement>) => {

        ev.preventDefault();

        const errors = validate(this.state.values, formValidation);

        if (!errors) {
            const {email, password, verificationCode} = this.state.values;
            securityApi.changePassword(email, password, verificationCode)
                .then(() => toast.success("Password changed successfully"))
                .then(() => this.props.push(SIGH_IN_PATH))
                .catch(handleErrorResponse);
        } else {
            this.setState({errors});
        }
    };
}

export default connector(PasswordRecoveryForm);
