import React, {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";

import {APP_PATH} from "../../helpers/constants";
import {AuthContext} from "../index";

const PublicRoute = () => {
    const {user} = useContext(AuthContext);
    return !user ? <Outlet/> : <Navigate to={`/${APP_PATH}`}/>
}

export default PublicRoute;