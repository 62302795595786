// Common constants
export const USER = "USER";
export const ROLE_USER = "ROLE_USER";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const DATE_FORMAT = "YYYY-MM-DD kk:mm:ss";

// Asset ids
export const BITCOIN = 1;

// Types of notifications
export const SPOT_ARBITRAGE = "SPOT_ARBITRAGE";

// Application paths
export const SIGH_IN_PATH = "sigh-in";
export const PASSWORD_RECOVERY_PATH = "restore-password";
export const APP_PATH = "app";
export const SPOT_ARBITRAGE_PATH = "arbitrage/spot";
export const ACCOUNTS_PATH = "control/accounts";
export const PASSWORD_CHANGE_PATH = "account/change-password";