import React, {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";

import {APP_PATH, ROLE_ADMIN} from "../../helpers/constants";
import {AuthContext} from "../index";

const AdminRoute = () => {
    const {user} = useContext(AuthContext);
    return user && (user.role === ROLE_ADMIN) ? <Outlet/> : <Navigate to={`/${APP_PATH}`}/>
}

export default AdminRoute;