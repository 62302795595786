import React, {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";

import {SIGH_IN_PATH} from "../../helpers/constants";
import {AuthContext} from "../index";

const PrivateRoute = () => {
    const {user} = useContext(AuthContext);
    return user ? <Outlet/> : <Navigate to={`/${SIGH_IN_PATH}`}/>
}

export default PrivateRoute;