import React from "react";
import {Col, Container, Row} from "reactstrap";
import classNames from "classnames";

import {CustomScrollbars, EmptyPane, LoadingPane} from "../../../../../../components";
import {ArbitrageItem} from "../../helpers/types";
import {Chains, Mark, Markets} from "./components";

import styles from "./index.module.scss";

interface Props {
    items?: ArbitrageItem[]
    selectedItemKey?: string
    usedForBuying?: boolean
    selectItem: (itemKey: string) => void
}

const ArbitrageItems = ({items, usedForBuying, selectedItemKey, selectItem}: Props) => (<>
    {!items && <LoadingPane/>}
    {items && (items.length === 0) && <EmptyPane/>}
    {items && (items.length > 0) && <CustomScrollbars>{items.map((item: ArbitrageItem) => (
        <Container fluid key={item.markets[0].key}
                   className={classNames([styles.item], {[styles.selected]: item.markets[0].key === selectedItemKey})}
                   onMouseUp={() => selectItem(item.markets[0].key)}
        >
            <Row>
                <Col>
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href={item.exchange.url}
                       onMouseUp={e => e.stopPropagation()}
                       className={styles.link}
                    >
                        {item.exchange.name}
                    </a>
                </Col>
                <Col><Markets markets={item.markets} usedForBuying={usedForBuying}/></Col>
                <Col xs={2}><Chains chains={item.chains}/></Col>
                <Col xs={2}>
                    {item.chains.length !== 0 ? item.chains[0].confirm : "\u2013"}
                </Col>
                <Col xs={2}>
                    {item.chains.length !== 0 ? item.chains[0].withdrawFee : "\u2013"}
                </Col>
                <Col xs={1}>
                    {item.chains.length !== 0 ? item.chains[0].status : "\u2013"}
                </Col>
                <Col xs={1} className={styles.mark}>
                    <Mark warning={item.warning} hasProfit={item.markets[0].hasProfit}/>
                </Col>
            </Row>
        </Container>)
    )}</CustomScrollbars>}
</>)

export default ArbitrageItems;