import React from "react";

import {CustomScrollbars} from "../../components";
import {SignUpForm, UsersTable} from "./components";

import styles from "./index.module.scss";

const AccountsView = () => (
    <CustomScrollbars>
        <div className={styles.wrapper}>
            <SignUpForm/>
            <UsersTable/>
        </div>
    </CustomScrollbars>
)

export default AccountsView;