import {Action} from "redux";

import {Notification} from "../../client/types";

export const SAVE_NOTIFICATIONS = "SAVE_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";

export type NotificationState = Notification[];

export interface SaveNotificationsAction extends Action<typeof SAVE_NOTIFICATIONS> {
    payload: Notification[]
}

export interface DeleteNotificationsAction extends Action<typeof DELETE_NOTIFICATIONS> {
}