import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {BITCOIN} from "../../helpers/constants";
import {CustomScrollbars} from "../../components";
import {AssetDetails, Sidebar} from "./components";

import styles from "./index.module.scss";

const ArbitrageView = () => (
    <CustomScrollbars renderTrackVertical={props => <div {...props} style={{display: "none"}}/>}>
        <div className={styles.wrapper}>
            <Sidebar/>
            <Routes>
                <Route index path="*" element={<Navigate to={`${BITCOIN}`}/>}/>
                <Route path=":assetId" element={<AssetDetails/>}/>
            </Routes>
        </div>
    </CustomScrollbars>
);

export default ArbitrageView;