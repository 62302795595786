import React, {Fragment, useContext} from "react";
import {Link} from "react-router-dom";
import {DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, UncontrolledDropdown} from "reactstrap";
import {BiUserCircle} from "react-icons/bi";
import {IoIosArrowForward} from "react-icons/io";
import classNames from "classnames";

import {ReactComponent as Logo} from "../../../../assets/icons/logo_2.svg";
import {ACCOUNTS_PATH, APP_PATH, SPOT_ARBITRAGE_PATH, PASSWORD_CHANGE_PATH, ROLE_ADMIN} from "../../../../helpers/constants";
import {AuthContext} from "../../../../components";

import styles from "./index.module.scss";

const Topbar = () => {
    const {user, removeUser} = useContext(AuthContext);
    const hiddenEmail = user ? `${user.email.slice(0, 3)}****${user.email.slice(user.email.indexOf("@"))}` : "";
    return (
        <Navbar className={styles.container}>
            <div className={styles.navBar}>
                <NavbarBrand tag={Link} to="/" className={styles.logoBox}>
                    <Logo className={styles.logo}/>
                </NavbarBrand>
                <Link to={`/${APP_PATH}/${SPOT_ARBITRAGE_PATH}`} className={styles.navItem}>
                    Spot Arbitrage
                </Link>
            </div>
            <UncontrolledDropdown>
                <DropdownToggle className={styles.btnUserMenu}>
                    <BiUserCircle/>
                </DropdownToggle>
                <DropdownMenu className={styles.userMenu}>
                    <DropdownItem header className={classNames([styles.itemBox], [styles.userEmail])}>
                        {hiddenEmail}
                    </DropdownItem>
                    <DropdownItem divider className={styles.divider}/>
                    <DropdownItem className={styles.dropdownItem}>
                        <Link to={`/${APP_PATH}/${PASSWORD_CHANGE_PATH}`}
                              className={classNames([styles.itemBox], [styles.link])}
                        >
                            <div className={styles.item}>
                                Security
                                <IoIosArrowForward className={styles.icon}/>
                            </div>
                        </Link>
                    </DropdownItem>
                    {
                        user && (user.role === ROLE_ADMIN) &&
                        <Fragment>
                            <DropdownItem divider className={styles.divider}/>
                            <DropdownItem className={styles.dropdownItem}>
                                <Link to={`/${APP_PATH}/${ACCOUNTS_PATH}`}
                                      className={classNames([styles.itemBox], [styles.link])}
                                >
                                    <div className={styles.item}>
                                        Accounts
                                        <IoIosArrowForward className={styles.icon}/>
                                    </div>
                                </Link>
                            </DropdownItem>
                        </Fragment>
                    }
                    <DropdownItem divider className={styles.divider}/>
                    <DropdownItem id="logout"
                                  className={classNames([styles.itemBox], [styles.btnLogout])}
                                  onMouseUp={() => removeUser()}
                    >
                        Log out
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Navbar>
    );
}

export default Topbar;