import {Notification} from "../../client/types";

import {DELETE_NOTIFICATIONS, DeleteNotificationsAction, SAVE_NOTIFICATIONS, SaveNotificationsAction} from "./types";

export const saveNotifications = (notifications: Notification[]): SaveNotificationsAction => ({
    type: SAVE_NOTIFICATIONS,
    payload: notifications
});

export const deleteNotifications = (): DeleteNotificationsAction => ({
    type: DELETE_NOTIFICATIONS
});