import {SpotVsDex, SpotVsSpot} from "../../../../../client/types";
import {ArbitrageProfit} from "./types";

export const convertSpotVsSpot = (spotVsSpotProfits: SpotVsSpot[]): ArbitrageProfit[] => (
    spotVsSpotProfits.map(spotVsSpot => ({
        srcMarketKey: `${spotVsSpot.srcId}`,
        destMarketKey: `${spotVsSpot.destId}`,
        investment: spotVsSpot.investment,
        profit: spotVsSpot.profit
    }))
)

export const convertSpotVsDex = (spotVsDexProfits: SpotVsDex[], reverse: boolean): ArbitrageProfit[] => (
    spotVsDexProfits.map(spotVsDex => {
        const srcMarketKey = `${spotVsDex.marketId}`;
        const destMarketKey = `${spotVsDex.networkId}-${spotVsDex.tokenAddress}`;
        return {
            srcMarketKey: reverse ? destMarketKey : srcMarketKey,
            destMarketKey: reverse ? srcMarketKey : destMarketKey,
            investment: spotVsDex.investment,
            profit: spotVsDex.profit,
        }
    })
)