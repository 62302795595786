import React from "react";

import {AuthContext, CustomScrollbars} from "../../components";
import {SignInForm} from "./components";
import {ReactComponent as Logo} from "../../assets/icons/logo.svg";

import styles from "./index.module.scss";

const SignInView = () => (
    <CustomScrollbars>
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Logo className={styles.logo}/>
                </div>
                <AuthContext.Consumer>
                    {({saveUser}) => <SignInForm saveUser={saveUser}/>}
                </AuthContext.Consumer>
            </div>
        </div>
    </CustomScrollbars>
);

export default SignInView;