import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import classNames from "classnames";
import {IoIosArrowDown} from "react-icons/io";

import {Market} from "../../../../helpers/types";

import styles from "./index.module.scss";

interface Props {
    markets: Market[],
    usedForBuying?: boolean
}

const Markets = ({markets, usedForBuying}: Props) => {

    let hasAdditionalProfit = false;
    for (let i = 1; i < markets.length; i++) {
        if (markets[i].hasProfit) {
            hasAdditionalProfit = true;
            break;
        }
    }

    return (<>
        {markets.length === 0 && "\u2013"}
        {markets.length === 1 &&
            <a target="_blank"
               rel="noopener noreferrer"
               href={usedForBuying ? markets[0].buyUrl : markets[0].sellUrl}
               onMouseUp={e => e.stopPropagation()}
               className={styles.link}
            >
                {markets[0].symbol}
            </a>}
        {markets.length > 1 && <UncontrolledDropdown className={styles.dropdown}>
            <DropdownToggle className={styles.btnDropdown}>
                <div onClick={e => {
                    if (e.target instanceof HTMLElement && !e.target.classList.contains("btnArrow")) {
                        e.stopPropagation()
                    }
                }}>
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href={usedForBuying ? markets[0].buyUrl : markets[0].sellUrl}
                       onMouseUp={e => e.stopPropagation()}
                       className={styles.link}
                    >
                        {markets[0].symbol}
                    </a>
                    <span className={classNames([styles.btnArrow], {[styles.hasProfit]: hasAdditionalProfit})}
                          onMouseUp={e => e.stopPropagation()}
                    >
                        <IoIosArrowDown/>
                    </span>
                </div>
            </DropdownToggle>
            <DropdownMenu className={styles.dropdownMenu}>
                {markets.slice(1).map((market: Market) => (
                    <DropdownItem
                        key={market.key}
                        tag={"a"}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={usedForBuying ? market.buyUrl : market.sellUrl}
                        className={styles.dropdownItem}
                    >
                        <span>{market.symbol}</span>
                        {market.hasProfit && <span className={styles.profitSign}>$</span>}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>}
    </>)
}

export default Markets;