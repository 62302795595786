import {Component} from "react";
import {bindActionCreators} from "redux";
import {connect, ConnectedProps} from "react-redux";

import * as notificationApi from "../../client/api/notificationApi";
import {AppDispatch} from "../../store/store";
import {deleteNotifications, saveNotifications} from "../../store/notification/actions";

// @ts-ignore
import alertSound from "../../assets/audio/alert.mp3";

const player = new Audio(alertSound);

const mapDispatchToProps = (dispatch: AppDispatch) => bindActionCreators(
    {deleteNotifications, saveNotifications}, dispatch
);

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
    children: any
}

interface State {
    hasNotifications?: boolean,
    notificationTime: number
}

class NotificationProvider extends Component<Props, State> {

    private updateTask?: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);
        this.state = {notificationTime: 0}
    }

    componentDidMount() {
        this.props.deleteNotifications();
        this.fetchNotifications();
        this.updateTask = setInterval(this.fetchNotifications, 1000);
    }

    render = () => this.props.children;

    componentDidUpdate() {

        const minInterval = 10000; // 10 seconds
        const currentTime = new Date().getTime();
        const deltaTime = currentTime - this.state.notificationTime;

        if (this.state.hasNotifications && deltaTime > minInterval) {
            player.play().then(() => this.setState({notificationTime: currentTime}));
        }
    }

    componentWillUnmount() {
        clearInterval(this.updateTask as NodeJS.Timeout);
    }

    fetchNotifications = () => {
        notificationApi.fetchNotifications()
            .then(({data}) => {
                this.props.saveNotifications(data);
                this.setState({hasNotifications: data.length > 0})
            });
    }
}

export default connector(NotificationProvider);