import React from "react";

import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";

import styles from "./index.module.scss";

interface Props {
    from: number,
    to: number,
    total: number,
    hasPrevious: boolean,
    onPrevious: () => void,
    hasNext: boolean
    onNext: () => void
}

const SimplePagination = ({from, to, total, hasPrevious, hasNext, onNext, onPrevious}: Props) => (
    <div className={styles.container}>
        <span className={styles.info}>{`${from}-${to} of ${total}`}</span>
        <button onMouseUp={onPrevious} disabled={!hasPrevious}>
            <IoIosArrowBack/>
        </button>
        <button onMouseUp={onNext} disabled={!hasNext}>
            <IoIosArrowForward/>
        </button>
    </div>
);

export default SimplePagination;