import React, {ChangeEvent, Component} from "react";
import {FormGroup, Input, Table} from "reactstrap";
import {toast} from "react-toastify";
import moment from "moment";
import {FiRefreshCw} from "react-icons/fi";

import {handleErrorResponse} from "../../../../helpers/errorHandler";
import {DATE_FORMAT, ROLE_ADMIN} from "../../../../helpers/constants";
import {Page, User} from "../../../../client/types";
import * as userApi from "../../../../client/api/userApi";
import {EmptyPane, LoadingPane, SimplePagination} from "../../../../components";

import styles from "./index.module.scss";

const defaultPageParams = {
    page: 0,
    size: 10,
    sort: "createDate,desc"
}

interface State {
    page?: Page<User[]>
}

class UsersTable extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.handleReloadPage();
    }

    render() {

        let from = 0;
        let to = 0;
        let total = 0;
        let hasPrevious = false;
        let hasNext = false;

        if (this.state.page && this.state.page.totalElements > 0) {
            from = this.state.page.number * this.state.page.size + 1;
            to = from + this.state.page.numberOfElements - 1;
            total = this.state.page.totalElements;
            hasPrevious = !this.state.page.first;
            hasNext = !this.state.page.last;
        }

        return (
            <section className={styles.container}>
                <div className={styles.header}>
                    <span className={styles.title}>Accounts</span>
                    <button className={styles.btnReload} onMouseUp={this.handleReloadPage}>
                        <FiRefreshCw/>
                    </button>
                </div>
                <div className={styles.main}>
                    <Table hover>
                        <thead>
                        <tr>
                            <th>Email</th>
                            <th className={styles.userRoleCol}>Role</th>
                            <th className={styles.createDateCol}>Created</th>
                            <th className={styles.userStatusCol}>Active</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.page && this.state.page.content.map((user: User) => (
                            <tr key={user.id}>
                                <td>{user.email}</td>
                                <td className={styles.userRoleCol}>{user.role.split("_")[1].toLowerCase()}</td>
                                <td className={styles.createDateCol}>
                                    {moment(moment.parseZone(user.createDate, DATE_FORMAT).toDate()).format(DATE_FORMAT)}
                                </td>
                                <td className={styles.userStatusCol}>
                                    <FormGroup switch className={styles.switchBox}>
                                        <Input
                                            id={`${user.id}`}
                                            type="switch"
                                            role="switch"
                                            checked={user.enabled}
                                            disabled={user.role === ROLE_ADMIN}
                                            onChange={this.handleChangeUserStatus}
                                        />
                                    </FormGroup>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {!this.state.page && <div className={styles.loadingContainer}><LoadingPane/></div>}
                    {this.state.page && (this.state.page.content.length === 0) &&
                        <div className={styles.emptyPaneContainer}><EmptyPane/></div>
                    }
                </div>
                <div className={styles.footer}>
                    <SimplePagination
                        from={from}
                        to={to}
                        total={total}
                        hasPrevious={hasPrevious}
                        hasNext={hasNext}
                        onPrevious={this.handlePreviousPage}
                        onNext={this.handleNextPage}
                    />
                </div>
            </section>
        )
    }

    handleChangeUserStatus = (ev: ChangeEvent<HTMLInputElement>) => {
        userApi.updateUserStatus(Number(ev.target.id), ev.target.checked)
            .then(() => toast.success("User status changed"))
            .then(() => this.handleReloadPage())
            .catch(handleErrorResponse);
    };

    handlePreviousPage = () => {
        if (this.state.page && !this.state.page.first) {
            this.fetchUsers(this.state.page.number - 1, this.state.page.size, defaultPageParams.sort);
        }
    }

    handleNextPage = () => {
        if (this.state.page && !this.state.page.last) {
            this.fetchUsers(this.state.page.number + 1, this.state.page.size, defaultPageParams.sort);
        }
    }

    handleReloadPage = () => {
        if (this.state.page) {
            this.fetchUsers(this.state.page.number, this.state.page.size, defaultPageParams.sort);
        } else {
            this.fetchUsers(defaultPageParams.page, defaultPageParams.size, defaultPageParams.sort);
        }
    }

    fetchUsers = (page: number, size: number, sort: string) => {
        userApi.fetchUsers(page, size, sort).then(({data}) => this.setState({page: data}));
    }
}

export default UsersTable;