import React from "react";
import classNames from "classnames";
import {AiFillStar} from "react-icons/ai";

import {favoriteArbitrageAsset} from "../../../../../../client/api/spotArbitrageApi";

import styles from "./index.module.scss";

interface Props {
    assetId: number,
    isFavorite?: boolean
}

const Favorite = ({assetId, isFavorite}: Props) => (
    <button className={classNames([styles.btnFav], {[styles.active]: isFavorite})}
            onMouseUp={() => favoriteArbitrageAsset(assetId)}
    >
        <AiFillStar/>
    </button>
)

export default Favorite;