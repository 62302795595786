import {AxiosResponse} from "axios";
import qs from "qs";

import {customAxios} from "../../helpers/customAxios";
import {User} from "../types";

const SIGN_UP_PATH = "/security/signUp";
const SIGN_IN_PATH = "/security/signIn";
const RESTORE_PASSWORD_PATH = "/security/restorePassword";
const CHANGE_PASSWORD_PATH = "/security/changePassword";
const UPDATE_PASSWORD_PATH = "/security/updatePassword";

export const signUp = (email: string): Promise<AxiosResponse> => (
    customAxios.post(SIGN_UP_PATH, {email})
);

export const signIn = (email: string, password: string): Promise<AxiosResponse<User>> => (
    customAxios.post(SIGN_IN_PATH, qs.stringify({email, password}))
);

export const restorePassword = (email: string): Promise<AxiosResponse> => (
    customAxios.get(RESTORE_PASSWORD_PATH, {params: {email}})
);

export const changePassword = (email: string, password: string, token: string): Promise<AxiosResponse> => (
    customAxios.post(CHANGE_PASSWORD_PATH, {email, password, token})
);

export const updatePassword = (currentPassword: string, newPassword: string): Promise<AxiosResponse> => (
    customAxios.post(UPDATE_PASSWORD_PATH, {currentPassword, newPassword})
);