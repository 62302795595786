import React, {ChangeEvent, Component, FormEvent} from "react";
import validate from "validate.js";
import produce from "immer";
import {Button, Form, FormGroup} from "reactstrap";
import {toast} from "react-toastify";

import * as securityApi from "../../../../client/api/securityApi";
import {handleErrorResponse} from "../../../../helpers/errorHandler";
import {FloatingLabelInput} from "../../../../components";

import styles from "./index.module.scss";

const formValidation = {
    currentPassword: {
        presence: {
            allowEmpty: false,
            message: "^Please enter current password"
        }
    },
    newPassword: {
        presence: {
            allowEmpty: false,
            message: "^Please enter new password"
        },
        format: {
            pattern: "^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*\\d+)(?=.*[~`!@#$%^&*()+=_\\-{}|:;”’?/<>,.\\]\\[]+).{8,}$",
            message: "^Passwords must be at least 8 characters with one uppercase letter, one special character and one number"
        }
    },
    newPasswordConfirmation: {
        presence: {
            allowEmpty: false,
            message: "^Please enter the new password again"
        },
        equality: {
            attribute: "newPassword",
            message: "^The two passwords are different"
        }
    }
};

interface State {
    values: {
        currentPassword: string,
        newPassword: string,
        newPasswordConfirmation: string
    },
    errors: {
        currentPassword?: string[],
        newPassword?: string[],
        newPasswordConfirmation?: string[]
    }
}

class PasswordChangeForm extends Component<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            values: {
                currentPassword: "",
                newPassword: "",
                newPasswordConfirmation: ""
            },
            errors: {}
        }
    }

    render() {

        const {
            currentPassword: currentPasswordErrors,
            newPassword: newPasswordErrors,
            newPasswordConfirmation: newPasswordConfirmationErrors
        } = this.state.errors;

        const currentPasswordError = currentPasswordErrors ? currentPasswordErrors[0] : "";
        const newPasswordError = newPasswordErrors ? newPasswordErrors[0] : "";
        const newPasswordConfirmationError = newPasswordConfirmationErrors ? newPasswordConfirmationErrors[0] : "";

        return (
            <section className={styles.content}>
                <h3 className={styles.title}>Change Password</h3>
                <h6 className={styles.subTitle}>Change password for the account</h6>
                <Form onSubmit={this.handleSubmit} autoComplete="off" noValidate>
                    <FormGroup className="mb-4">
                        <FloatingLabelInput
                            id="currentPassword"
                            name="currentPassword"
                            type="password"
                            label="Current password"
                            value={this.state.values.currentPassword}
                            invalid={Boolean(currentPasswordError)}
                            helperText={currentPasswordError}
                            maxLength={20}
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <FloatingLabelInput
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            label="New password"
                            value={this.state.values.newPassword}
                            invalid={Boolean(newPasswordError)}
                            helperText={newPasswordError}
                            maxLength={20}
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <FloatingLabelInput
                            id="newPasswordConfirmation"
                            name="newPasswordConfirmation"
                            type="password"
                            label="Confirm new password"
                            value={this.state.values.newPasswordConfirmation}
                            invalid={Boolean(newPasswordConfirmationError)}
                            helperText={newPasswordConfirmationError}
                            maxLength={20}
                            onChange={this.handleChangeForm}
                        />
                    </FormGroup>
                    <Button type="submit" color="success" block>Confirm</Button>
                </Form>
            </section>
        )
    }

    handleChangeForm = (ev: ChangeEvent<HTMLInputElement>) => {

        const name = ev.target.name as "currentPassword" | "newPassword" | "newPasswordConfirmation";
        const value = ev.target.value;

        this.setState(produce(this.state, draft => {

            draft.values[name] = value;

            const errors = validate(draft.values, formValidation);

            if (errors) {
                draft.errors[name] = errors[name];
            } else {
                draft.errors[name] = undefined;
            }
        }));
    };

    handleSubmit = (ev: FormEvent<HTMLFormElement>) => {

        ev.preventDefault();

        const errors = validate(this.state.values, formValidation);

        if (!errors) {
            securityApi.updatePassword(this.state.values.currentPassword, this.state.values.newPassword)
                .then(() => toast.success("Password changed successfully"))
                .then(() => {
                    this.setState({
                        values: {
                            currentPassword: "",
                            newPassword: "",
                            newPasswordConfirmation: ""
                        }
                    });
                })
                .catch(handleErrorResponse);
        } else {
            this.setState({errors});
        }
    };
}

export default PasswordChangeForm;