import {TokenContract} from "../../../../../client/types";
import {Token} from "./types";

export const convertTokenContracts = (tokenContracts: TokenContract[]): Token[] => (
    tokenContracts.map(contract => {
        const {buyFee, sellFee} = contract;
        const buyFeeStr = buyFee ? `${roundTo(buyFee, 2)} %` : Number.isFinite(buyFee) ? "No" : "\u2013";
        const sellFeeStr = sellFee ? `${roundTo(sellFee, 2)} %` : Number.isFinite(sellFee) ? "No" : "\u2013";
        return {
            chain: contract.network.symbol,
            address: contract.address,
            buyFee: buyFeeStr,
            sellFee: sellFeeStr
        }
    })
)

function roundTo(num: number, decimals: number) {
    const tenths = Math.pow(10, decimals);
    return Math.round((num + Number.EPSILON) * tenths) / tenths;
}