import React from "react";

import {Asset} from "../../../../../../client/types";
import {ArbitrageProfit} from "../../helpers/types";

import styles from "./index.module.scss";

interface Props {
    asset?: Asset,
    profit?: ArbitrageProfit
}

const Ticker = ({asset, profit}: Props) => (<div className={styles.ticker}>
        <span className={styles.asset}>
            {asset ? `${asset.name} (${asset.symbol.toUpperCase()})` : "\u2013"}
        </span>
        <dl className={styles.investment}>
            <dt>Investment</dt>
            <dd>{profit ? `${Math.round(profit.investment)} $` : "\u2013"}</dd>
        </dl>
        <dl className={styles.percent}>
            <dt>Percent</dt>
            <dd>
                {profit ? `${(Math.round((profit.profit / profit.investment * 100) * 10) / 10).toFixed(1)} %` : "\u2013"}
            </dd>
        </dl>
        <dl className={styles.profit}>
            <dt>Profit</dt>
            <dd>{profit ? `${Math.round(profit.profit)} $` : "\u2013"}</dd>
        </dl>
    </div>
);

export default Ticker;