import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import Countdown from "react-countdown";
import {CountdownRenderProps} from "react-countdown/dist/Countdown";
import {VscMute, VscUnmute} from "react-icons/vsc";

import {muteArbitrageAsset} from "../../../../../../client/api/spotArbitrageApi";

import styles from "./index.module.scss";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../../helpers/constants";

interface Props {
    assetId: number,
    mutedUntilDate?: string
}

const Mute = ({assetId, mutedUntilDate}: Props) => {

    let parsedDate = mutedUntilDate ? moment.parseZone(mutedUntilDate, DATE_FORMAT).toDate() : undefined;
    const isMuted = parsedDate && parsedDate.getTime() > Date.now();

    const countdownRenderer = ({days, hours, minutes, seconds}: CountdownRenderProps) => {

        let message;

        if (days) {
            message = `Muted for ${days} d`;
        } else if (hours) {
            message = `Muted for ${hours} h`;
        } else if (minutes) {
            message = `Muted for ${minutes} m`;
        } else if (seconds) {
            message = `Muted for ${seconds} s`;
        }

        return <span className={styles.message}>{message}</span>
    };

    return (
        <div className={styles.mute}>
            {isMuted &&
                <Countdown date={parsedDate} renderer={countdownRenderer}/>}
            <UncontrolledDropdown>
                <DropdownToggle className={styles.btnDropdown}>
                    {isMuted ? <VscMute/> : <VscUnmute/>}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Mute for</DropdownItem>
                    <DropdownItem onMouseUp={() => muteArbitrageAsset(assetId, 5)}>
                        5 minutes
                    </DropdownItem>
                    <DropdownItem onMouseUp={() => muteArbitrageAsset(assetId, 60)}>
                        1 hour
                    </DropdownItem>
                    <DropdownItem onMouseUp={() => muteArbitrageAsset(assetId, 360)}>
                        6 hours
                    </DropdownItem>
                    <DropdownItem onMouseUp={() => muteArbitrageAsset(assetId, 720)}>
                        12 hours
                    </DropdownItem>
                    <DropdownItem onMouseUp={() => muteArbitrageAsset(assetId, 10080)}>
                        7 days
                    </DropdownItem>
                    <DropdownItem onMouseUp={() => muteArbitrageAsset(assetId, 43200)}>
                        30 days
                    </DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem disabled={!isMuted} onMouseUp={() => muteArbitrageAsset(assetId, 0)}>
                        Unmute
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

export default Mute;