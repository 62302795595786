import {combineReducers, configureStore, ThunkDispatch} from "@reduxjs/toolkit";
import {createRouterMiddleware, createRouterReducer} from "@lagunovsky/redux-react-router"

import {browserHistory} from "../helpers/browserHistory";
import {reducer as notificationReducer} from "./notification/reducer";

const rootReducer = combineReducers({
    notifications: notificationReducer,
    router: createRouterReducer(browserHistory)
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(createRouterMiddleware(browserHistory)),
});

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = ThunkDispatch<RootState, unknown, any>