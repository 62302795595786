import React from "react";
import {DropdownMenu, DropdownToggle, Table, UncontrolledDropdown} from "reactstrap";

import {ReactComponent as TokenIcon} from "../../../../../../assets/icons/token.svg";
import {Token} from "../../helpers/types";

import styles from "./index.module.scss";

interface Props {
    tokens?: Token[]
}

const Tokens = ({tokens}: Props) => (
    <UncontrolledDropdown className={styles.tokens}>
        <DropdownToggle disabled={!tokens || tokens.length === 0} className={styles.btnDropdown}>
            <TokenIcon className={styles.icon}/>
        </DropdownToggle>
        {tokens && tokens.length > 0 &&
            <DropdownMenu>
                <Table borderless>
                    <thead>
                    <tr>
                        <th className={styles.colChain}>Chain</th>
                        <th className={styles.colBuyFee}>Buy fee</th>
                        <th className={styles.colSellFee}>Sell fee</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tokens.map(token => (
                        <tr>
                            <td className={styles.colChain}>{token.chain}</td>
                            <td className={styles.colBuyFee}>{token.buyFee}</td>
                            <td className={styles.colSellFee}>{token.sellFee}</td>
                        </tr>)
                    )}
                    </tbody>
                </Table>
            </DropdownMenu>}
    </UncontrolledDropdown>
)

export default Tokens;