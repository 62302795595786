import React from "react";
import {createRoot} from 'react-dom/client';
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";

import {store} from "./store/store";
import {AppRoutes, AuthProvider} from "./components";

import "./assets/styles/index.scss";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <Provider store={store}>
                <AppRoutes/>
            </Provider>
        </AuthProvider>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            closeButton={false}
            theme="colored"
        />
    </React.StrictMode>
);